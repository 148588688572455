<div class="content" role="main">
  <div>
    <app-introduction></app-introduction>
    <app-socials></app-socials>
  </div>
</div>

<div class="profile-footer">
  <img src="assets/photo-small.png" class="image-cover">
</div>

<router-outlet></router-outlet>
